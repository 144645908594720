<template lang="pug">
el-tooltip(
  v-if="content || $slots.content"
  effect="light"
  :teleported="teleported"
  :content="content"
  :raw-content="rawContent"
  :placement="tooltipPosition || 'bottom'"
  :hide-after="hideAfter"
)
  .on-icon
    slot
      ui-icon.hint-icon(
        :class="type && `_${ type }`"
        :icon="icon"
        :size="size === 'small' ? 12 : 16"
      )
  template(v-if="$slots.content" v-slot:content)
    slot(name="content")

slot(v-else)
  ui-icon.hint-icon(
    :class="type && `_${ type }`"
    :icon="icon"
    :size="size === 'small' ? 12 : 16"
  )
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "OldTooltip",
  components: {
    UiIcon,
  },
  props: {
    teleported: {
      type: Boolean,
      default: true,
    },
    rawContent: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
    tooltipPosition: {
      type: String as PropType<"top" | "right" | "bottom" | "bottom-start" | "bottom-end" | "left" | "right-start" | "right-end">,
      default: "bottom",
    },
    icon: {
      type: String,
      default: UiIconNames.Icon_HelpCircle,
    },
    type: {
      type: String as PropType<"danger" | "info">,
      default: "danger",
    },
    size: {
      type: String as PropType<'small'|'default'>,
      default: 'default',
    },
    hideAfter: {
      type: Number,
      default: 50,
    },
  },
})
</script>

<style scoped lang="scss">
.hint-icon {
  color: var(--main-red-color);

  &._info {
    color: #8c8c8c;
  }
}

.on-icon {
  display: flex;
  align-items: center;
}
</style>
